.editor-container {
  width: 100%;
  padding: 8px;
  background: #fff;
  border: 2px solid #e7edee; /* MUI-like border */
  border-radius: 4px; /* Rounded corners */
  display: flex;
  flex-direction: column;
  margin-top: 26px;
}

.editor-box {
  height: 80px;
  width: 100%;
  padding: 10px;
  background-color: #f9f9f9; /* Light background */
  outline: none;
  resize: none;
  font-size: 16px;
}

